.listItem {
  color: var(--blue-primary);
  background-color: var(--blue-light-6);
  font-size: 0.75rem;

  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
}
